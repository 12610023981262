.container {
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  width: 100%;

  .formContainer {
    padding-right: 24px;
  }

  .widthHalfPage {
    width: 50vw;
  }
}