@import url(https://fonts.googleapis.com/css?family=Quicksand);
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Login_container__3TrkC{min-height:100vh;display:grid;place-items:center}.Login_container__3TrkC .Login_card__3qehz{background:#212121;color:#fff;padding:8% 2%;height:450px;width:500px;border-radius:25px}.Login_container__3TrkC .Login_card__3qehz .Login_title__15XgG{text-align:center;font-weight:bold;font-size:1.2rem}.Login_container__3TrkC .Login_card__3qehz .Login_button__qEAdE{margin-top:20px;flex-grow:1;height:40px;width:10px;border:8px}
.Home_home__2SyRU{display:flex;width:100%;overflow:hidden;height:90vh}.Home_home__2SyRU .Home_drawerContainer__3-yrr{min-width:250px}.Home_home__2SyRU .Home_drawer__d6MER>.MuiPaper-root{margin-top:70px}.Home_home__2SyRU .Home_contentContainer__H-Lrf{width:100%;padding:10px 0px;overflow:auto;height:90vh}
.PageContentLayout_homeContent__3li1a .PageContentLayout_header__3RNeP{display:flex;justify-content:flex-end;padding-right:20px}
.HorizontalTabs_box__3O6Qa{border-bottom:1px solid;border-color:rgba(0,0,0,.12)}
.Table_tableContainer__2tSKh.MuiTableContainer-root{overflow-x:inherit}.Table_tableContainer__2tSKh.MuiTableContainer-root .Table_table__1edT0 .Table_tableHead__3ztYR>tr>th{font-weight:bold;width:150px}.Table_tableContainer__2tSKh.MuiTableContainer-root .Table_table__1edT0 .Table_tableBody__2k_N0 .Table_tableRow__3Wsut .Table_img__ywu3w{width:150px;height:150px;object-fit:cover}.Table_tableContainer__2tSKh.MuiTableContainer-root .Table_table__1edT0 .Table_tableBody__2k_N0 .Table_tableRow__3Wsut td{width:150px}.Table_tableContainer__2tSKh.MuiTableContainer-root .Table_table__1edT0 .Table_tableBody__2k_N0 .Table_tableRow__3Wsut:last-child td,.Table_tableContainer__2tSKh.MuiTableContainer-root .Table_table__1edT0 .Table_tableBody__2k_N0 .Table_tableRow__3Wsut:last-child th{border:0}
.Loading_loading__2z2P2{width:100%;height:80vh;display:flex;justify-content:center;align-items:center}
.PreviewContainer_container__23UEb{min-height:90vh;border:2px solid #000}.PreviewContainer_container__23UEb .PreviewContainer_textCenter__3ccqf{text-align:center}
.FormPageLayout_container__1BsEu{padding:16px 24px;display:flex;flex-direction:row;width:100%}.FormPageLayout_container__1BsEu .FormPageLayout_formContainer__2iT8I{padding-right:24px}.FormPageLayout_container__1BsEu .FormPageLayout_widthHalfPage__2aUoB{width:50vw}
.FullScreenDialog_dialog__2kir7 .FullScreenDialog_appBar__2PdOM{position:relative}.FullScreenDialog_title__3_iyu{flex-grow:1;margin-left:4px}
#toolbar{border-radius:8px;margin:10px 0;display:flex;flex-wrap:wrap;align-items:center;padding:15px 10px;grid-row-gap:15px;row-gap:15px}#toolbar-grp>*{margin-right:10px;cursor:pointer}#toolbar-grp{margin:0 10px}.ql-container{font-family:"Quicksand",sans-serif;border-radius:8px;min-height:150px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.ql-snow{font-family:"Quicksand",sans-serif !important}
.AddProject_form__ZXAV1 .AddProject_spacing__rpJ0-{margin:8px 0}.AddProject_form__ZXAV1 .AddProject_select__1NrUf{width:47vw}
.AddProfile_form__bUl6z .AddProfile_spacing__lR07N{margin:8px 0}.AddProfile_form__bUl6z .AddProfile_widthHalfPage__CP3WW{width:50vw}
.AddBlog_form__3O7JT .AddBlog_spacing__2aAzF{margin:8px 0}.AddBlog_form__3O7JT .AddBlog_widthHalfPage__3hP3d{width:50vw}
